import { Avatar, Card, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#2E3B55',
    color: 'white',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  },
  title: {
    margin: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: 80,
    height: 80,
    margin: 16,
  },
  progress: {
    width: '80%',
    margin: '16px 0',
  },
});

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#2E3B55',
//     },
//     secondary: {
//       main: '#FFC107',
//     },
//   },
// });

const variants = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 20,
    },
  },
};

function AnimatedSkillsCard({ title, proficiency, imgSrc }) {
  const classes = useStyles();
  const cardRef = React.useRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Card className={classes.root}>
      <Avatar alt={title} src={imgSrc} />
    </Card>
  );
}
export default AnimatedSkillsCard;
