// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Avatar, Card, CardMedia, Grid, Typography } from '@mui/material';
// utils

import useResponsive from '../../../hooks/useResponsive';
import jackImage from '../../../images/jack.png';
import jackImage1 from '../../../images/jack2.png';
// eslint-disable-next-line import/no-unresolved

import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import { BlogPostCard } from '../blog';
import POSTS from '../../../_mock/blog';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  const mdUp = useResponsive('up', 'md');
  return (
    <Card
      sx={{
        height: '600px',
        py: 5,
        mt: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Grid container sx={{}} flexDirection={!mdUp ? 'row' : 'row-reverse'}>
        <Grid item xs={6} sm={6} md={6}>
          {/* <img src={jackImage} alt="jaypalsinh" style={{ width: '100%', height: '100%' }} /> */}
          <CardMedia
            component="img"
            sx={{
              width: 351,
              height: 551,

              objectFit: 'container',

              alignItems: 'center',
              textAlign: 'center',
              '& .MuiCardMedia-img': {
                // width: '100%',
                // height: '100%',
                height: '474px',
                width: '366px',

                borderRadius: '85px',
              },
            }}
            image={jackImage1}
            alt="Live from space album cover"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ p: 4 }}>
          <Typography
            variant="h2"
            textAlign={'left'}
            sx={{ background: '#ffff', width: 'fit-content', borderRadius: '12px', px: 2 }}
          >
            Jaypalsinh Gohil
          </Typography>
          <Typography variant="h5" sx={{ mt: 7, opacity: 0.72 }}>
            {'My name is Jaypalsinh Gohil and I am a software developer.'}
          </Typography>
          <Typography variant="h5" sx={{ opacity: 0.72 }}>
            {'I have a passion for building web applications and have'}
          </Typography>
          <Typography variant="h5" sx={{ opacity: 0.72 }}>
            {'worked on a variety of projects using technologies,'}
          </Typography>
          <Typography variant="h5" sx={{ opacity: 0.72 }}>
            {'such as React, Node.js, and Express.'}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
