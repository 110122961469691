import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import jackImage from '../images/myimage1.png';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 0,
    textAlign: 'center',
    color: theme.palette.primary.darker,
    backgroundColor: theme.palette.primary.lighter,
    maxWidth: '1376px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    width: 'fit-content',
  },
  cover: {
    width: '100%',
    height: '520px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
}));

const MyCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container justifyContent={'center'} flexDirection={'column'} sx={{ height: '100%' }}>
            <Grid item>
              <Typography
                variant="h3"
                textAlign={'left'}
                sx={{ background: '#ffff', width: 'fit-content', borderRadius: '12px', px: 2, mb: 2 }}
              >
                Jaypalsinh Gohil
              </Typography>
            </Grid>
            <Grid item>
              <Box>
                {/* <Typography
                  variant="subtitle1"
                  textAlign={'left'}
                  color="textSecondary"
                  sx={{ width: 'fit-content', borderRadius: '12px', px: 2 }}
                >
                  Ishvariya
                </Typography> */}
                <Typography
                  variant="subtitle1"
                  textAlign={'left'}
                  color="textSecondary"
                  sx={{ width: 'fit-content', borderRadius: '12px', px: 2 }}
                >
                  {' '}
                  I am a skilled frontend developer with over 1 year of experience in React.js and a passion for
                  creating beautiful and functional web applications. With my expertise in React, I have worked with a
                  variety of technologies such as Next.js, Material UI, Ant Design, Storybook, Redux Toolkit, React
                  Query, Firebase, and MySQL. My experience and expertise allow me to tackle complex projects and
                  deliver high-quality results.
                </Typography>
                {/* <Typography
                  variant="subtitle1"
                  textAlign={'left'}
                  color="textSecondary"
                  sx={{ width: 'fit-content', borderRadius: '12px', px: 2 }}
                ></Typography> */}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </div>
      <CardMedia className={classes.cover} image={jackImage} title="Right-side image" />
    </Card>
  );
};

export default MyCard;
