import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: 'auto',
    maxWidth: '1376px',
    background: '#FFF5F1',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    textAlign: 'start',
  },
});

export default function SkillsCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2" textAlign={'start'}>
          Skills
        </Typography>
        <hr />
        <Typography className={classes.pos} color="textSecondary">
          <li>React JS</li>
          <li>Material-UI</li>
          <li>Responsive Design</li>
          <li>JavaScript</li>
          <li>HTML/CSS</li>
        </Typography>
      </CardContent>
    </Card>
  );
}
