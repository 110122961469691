import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Avatar, Typography } from '@mui/material';
// utils
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import account from 'src/_mock/account';
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  // maxWidth: '1336px',
  // [theme.breakpoints.up('1446px')]: {
  //   // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  //   padding: '0px 80px',
  // },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        {/* <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar /> */}
        <Typography variant="h5" color={'black'}>
          Jaypalsinh gohil
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <Avatar>
            <Link to="https://www.google.com" target="_blank">
              <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />
            </Link>
          </Avatar> */}
          <Avatar sx={{ background: 'transparent' }}>
            <Link
              // to="https://www.linkedin.com/in/jaypalsinh-gohil-953b53212"
              // target="_blank"
              onClick={() => {
                window.open('https://www.linkedin.com/in/jaypalsinh-gohil-953b53212');
              }}
            >
              <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />
            </Link>
          </Avatar>
          <Avatar sx={{ background: 'transparent' }}>
            <Link
              onClick={() => {
                window.open('https://www.instagram.com/jaypalsinh_o7/');
              }}
            >
              <Iconify icon={'skill-icons:instagram'} color="#1C9CEA" width={30} />
            </Link>
          </Avatar>
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          {/* <AccountPopover /> <AccountPopover /> <AccountPopover /> */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
